import { useState, useEffect, useCallback, useRef } from 'react';
import axios from "axios";
import Navbar from './Navbar';
import { usePdfContext } from '../context/PdfContext';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import jwtDecode from 'jwt-decode';
import { useParams } from 'react-router-dom';

function Home() {
    const { userId: userIdFromParams } = useParams();
    const [title, setTitle] = useState("");
    const [file, setFile] = useState("");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [newCategory, setNewCategory] = useState('');
    const [editingCategoryId, setEditingCategoryId] = useState(null); // ID de la catégorie en cours de modification
    const [categoryNameToUpdate, setCategoryNameToUpdate] = useState(''); // Nom de la catégorie en cours de modification
    const { allPdfs, setAllPdfs } = usePdfContext();
    const [profileImage, setProfileImage] = useState("");
    const [profileImageFile, setProfileImageFile] = useState(null);
    const navigate = useNavigate();
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const token = localStorage.getItem("token");
    const [userId, setUserId] = useState(""); // Ajout de l'état pour stocker l'ID de l'utilisateur
    const [mairieId, setMairieId] = useState(""); // Id mairie
    const fullUrl = window.location.origin; // Récuperer le nom de domaine
    const [errors, setErrors] = useState({}); // stockage des erreurs pour le form pdf
    const [pdfList, setPdfList] = useState([]); // mettre à jour les pdfs dans letat
    const fileInputRef = useRef(null); // ref pour le champs file pour le reset aprés upload
    const [success, setSuccess] = useState(null); // stockage des msg success
    const [categoryDeleteErrors, setCategoryDeleteErrors] = useState({}); // error msg si on delete une catégorie qui est relié à un ou plusieurs PDF
    const logoInputRef = useRef(null); // vider le champs d'image d'upload du logo lorsque l'upload est reussi
    const [isUploading, setIsUploading] = useState(false); // etat pour l'upload du fichier logo en cours
    const [isSuperAdmin, setIsSuperAdmin] = useState(false); // vérifier si cest superadmin qui gere les données
    const hasFetchedData = useRef(false); // assurer une seul requete 
    const [mairieName, setMairieName] = useState(""); // Stocker le nom de la mairie

    const authAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL+"/api",
        headers: {
            Authorization: token ? `Bearer ${token}` : null,
        },
    });

    useEffect(() => {
        if (!token) {
            alert("Vous devez être connecté pour accéder à cette page.");
            navigate('/');
            return;
        }
    
        try {
            const decodedToken = jwtDecode(token);
            setUserId(decodedToken.id);
            const isAdmin = decodedToken.role === "superadmin"; // Variable temporaire
            setIsSuperAdmin(isAdmin); 
    
        } catch (error) {
            console.error("Erreur lors du décodage du token:", error);
            navigate('/');
        }
    }, [token, navigate]);    
    

    const fetchUserData = useCallback(async () => {
        if (!token || hasFetchedData.current) return;
        hasFetchedData.current = true;
    
        try {
            console.log("📡 Récupération des données utilisateur...");
            const decodedToken = jwtDecode(token);
            setUserId(decodedToken.id);
            
            const isAdmin = decodedToken.role === "superadmin"; //  Variable temporaire
            setIsSuperAdmin(isAdmin); // On définit `isSuperAdmin` ici, mais on utilise `isAdmin` plus bas
    
            if (!isAdmin && userIdFromParams && decodedToken.id !== userIdFromParams) {
                alert("Accès refusé.");
                navigate('/');
                return;
            }
    
            // 🔹 Récupérer l'image de profil
            const profileEndpoint = isAdmin && userIdFromParams
                ? `/superadmin/get-user-profile/${userIdFromParams}`
                : "/get-user-profile";
    
            const profileResult = await authAxios.get(profileEndpoint);
            setProfileImage(profileResult.data.profileImage);
            setMairieId(profileResult.data.mairieId);
            setMairieName(profileResult.data.mairie);
            console.log(" Profil récupéré :", profileResult.data);
    
            // 🔹 Récupérer les catégories
            const categoriesEndpoint = isAdmin && userIdFromParams
                ? `/superadmin/get-categories/${userIdFromParams}`
                : "/get-categories";
    
            const categoriesResult = await authAxios.get(categoriesEndpoint);
            setCategories(categoriesResult.data.categories || []);
            console.log(" Catégories récupérées :", categoriesResult.data.categories);
    
            // 🔹 Récupérer les PDFs
            const pdfsEndpoint = isAdmin && userIdFromParams
                ? `/superadmin/get-files/${userIdFromParams}`
                : "/get-files";
    
            const pdfsResult = await authAxios.get(pdfsEndpoint);
            setAllPdfs(pdfsResult.data.data || []);
            console.log(" PDFs récupérés :", pdfsResult.data.data);
    
        } catch (error) {
            console.error(" Erreur lors de la récupération des données :", error);
        }
    }, [token, userIdFromParams, authAxios, navigate, setAllPdfs]);    

    const handleAddCategory = async () => {
        if (!newCategory.trim() || !token) return;
    
        try {
            const endpoint = isSuperAdmin && userIdFromParams
                ? `/superadmin/categories/${userIdFromParams}`
                : "/categories";
    
            const result = await authAxios.post(endpoint, { name: newCategory });
    
            // Mettre à jour l'état directement sans recharger toute la page
            setCategories(prevCategories => [...prevCategories, result.data.category]);
    
            setNewCategory('');
            setSuccess("Catégorie ajoutée avec succès !");
    
            setTimeout(() => setSuccess(null), 3000);
        } catch (error) {
            console.error(" Erreur ajout catégorie :", error);
            setErrors({ addCategoryError: "Impossible d'ajouter la catégorie." });
        }
    };    
    

    const handleUpdateCategory = async () => {
        if (!categoryNameToUpdate || !token || !editingCategoryId) return;
    
        try {
            const originalCategory = categories.find(category => category._id === editingCategoryId);
            if (!originalCategory) {
                console.error(" Catégorie introuvable avec l'ID :", editingCategoryId);
                return;
            }
    
            if (originalCategory.name === categoryNameToUpdate) {
                // Aucun changement, ne pas afficher le message de succès
                setEditingCategoryId(null);
                setCategoryNameToUpdate('');
                return;
            }
    
            // Vérifier si l'utilisateur est SuperAdmin pour appeler le bon endpoint
            const endpoint = isSuperAdmin
                ? `/superadmin/categories/${editingCategoryId}`
                : `/categories/${editingCategoryId}`;
    
            console.log(` Mise à jour de la catégorie ${editingCategoryId} via ${endpoint}`);
    
            const result = await authAxios.put(endpoint, { name: categoryNameToUpdate });
    
            setCategories(categories.map(category =>
                category._id === editingCategoryId ? result.data.category : category
            ));
            setEditingCategoryId(null);
            setCategoryNameToUpdate('');
            setSuccess("Catégorie modifiée avec succès"); // Mettre à jour l'état success
    
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la catégorie :", error.message || error);
        }
    };    

    const handleDeleteCategory = async (categoryId) => {
        if (!token || !categoryId) return;
    
        try {
            console.log(` Tentative de suppression de la catégorie : ${categoryId}`);
    
            // Vérification que la catégorie existe avant de tenter de la supprimer
            const categoryToDelete = categories.find(category => category._id === categoryId);
            if (!categoryToDelete) {
                console.error("Catégorie introuvable avec l'ID :", categoryId);
                return;
            }
    
            // Vérification des PDFs associés à la catégorie
            const pdfsLinkedToCategory = allPdfs.filter(pdf => 
                pdf.category && pdf.category._id === categoryId
            );
    
            if (pdfsLinkedToCategory.length > 0) {
                console.warn(`⚠️ Impossible de supprimer la catégorie ${categoryId}, car ${pdfsLinkedToCategory.length} PDF(s) y sont liés.`);
                
                // Affichage d'un message d'erreur
                setCategoryDeleteErrors(prevErrors => ({
                    ...prevErrors,
                    [categoryId]: `Impossible de supprimer cette catégorie car elle est actuellement reliée à ${pdfsLinkedToCategory.length} PDF(s).`
                }));
    
                setTimeout(() => {
                    setCategoryDeleteErrors(prevErrors => ({ ...prevErrors, [categoryId]: null }));
                }, 5000);
    
                return;
            }
    
            // Sélectionner le bon endpoint selon si c'est un superadmin ou non
            const endpoint = isSuperAdmin 
                ? `/superadmin/categories/${categoryId}`
                : `/categories/${categoryId}`;
    
            console.log(` Suppression via : ${endpoint}`);
    
            // Suppression de la catégorie
            await authAxios.delete(endpoint);
    
            // Mise à jour immédiate des catégories **sans recharger la page**
            setCategories(prevCategories => prevCategories.filter(category => category._id !== categoryId));
    
            console.log(` Catégorie ${categoryId} supprimée avec succès.`);
            setSuccess("Catégorie supprimée avec succès");
    
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
    
        } catch (error) {
            console.error(" Erreur lors de la suppression de la catégorie :", error.response?.data?.message || error.message || error);
    
            // Ajout de la gestion d'erreur pour SuperAdmin aussi
            setCategoryDeleteErrors(prevErrors => ({
                ...prevErrors,
                [categoryId]: error.response?.data?.message || "Erreur inconnue lors de la suppression de la catégorie."
            }));
    
            setTimeout(() => {
                setCategoryDeleteErrors(prevErrors => ({ ...prevErrors, [categoryId]: null }));
            }, 5000);
        }
    };    
    
    

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const onSubmit = async (e, redirectToPdfPage = false) => {
        e.preventDefault();
    
        if (!title || !file || !startDate || !selectedCategory) {
            setErrors({
                title: !title ? "Le titre est requis." : null,
                file: !file ? "Le fichier PDF est requis." : null,
                startDate: !startDate ? "La date de début est requise." : null,
                category: !selectedCategory ? "Veuillez sélectionner une catégorie." : null,
            });
            return;
        }
    
        if (new Date(endDate) < new Date(startDate)) {
            setErrors({ endDate: "La date de fin ne peut pas être antérieure à la date de début." });
            return;
        }
    
        const formData = new FormData();
        formData.append("title", title);
        formData.append("file", file);
        formData.append("startDate", startDate);
        if (endDate) {
            formData.append("endDate", endDate);
        }
        formData.append("category", selectedCategory);
        formData.append("mairieId", mairieId);
    
        try {
            const endpoint = isSuperAdmin && userIdFromParams
                ? `/superadmin/upload-files/${userIdFromParams}`
                : "/upload-files";
    
            await authAxios.post(endpoint, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
    
            setSuccess("PDF ajouté avec succès !");
            fetchUserData(); // Recharge la liste des PDFs après ajout

             // ** Réinitialiser les champs du formulaire**
            setTitle("");
            setFile(null);
            setStartDate("");
            setEndDate("");
            setSelectedCategory("");
            if (fileInputRef.current) fileInputRef.current.value = ""; // Réinitialiser le champ file input
    
            if (redirectToPdfPage) {
                navigate(isSuperAdmin && userIdFromParams ? `/documents/${userIdFromParams}` : `/documents`);
            }
        } catch (error) {
            console.error(" Erreur upload PDF :", error);
        }
    };    

    const handleProfileImageChange = async (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            setProfileImageFile(file); // Stocker le fichier dans l'état
    
            // Afficher un aperçu immédiatement
            const previewURL = URL.createObjectURL(file);
            setProfileImage(previewURL);
    
            // Déclencher l'upload immédiatement après sélection
            await handleProfileImageUpload(file);
        }
    };

    const handleProfileImageUpload = async (file) => {
        if (isUploading || !file) return;
    
        setIsUploading(true);
    
        try {
            // Supprimer l'ancien logo s'il existe
            if (profileImage && profileImage.startsWith("blob:")) {
                URL.revokeObjectURL(profileImage);
            }
            if (profileImage) {
                await authAxios.delete(
                    isSuperAdmin && userIdFromParams
                        ? `/superadmin/delete-profile-image/${userIdFromParams}`
                        : "/delete-profile-image"
                );
                console.log("Ancien logo supprimé avec succès.");
            }
    
            // Préparer l'upload
            const formData = new FormData();
            formData.append("profileImage", file);
    
            const endpoint = isSuperAdmin && userIdFromParams
                ? `/superadmin/upload-profile-image/${userIdFromParams}`
                : "/upload-profile-image";
    
            // Envoyer la requête
            const result = await authAxios.post(endpoint, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
    
            // Mettre à jour l'image avec le vrai chemin après l'upload
            setProfileImage(result.data.profileImage);
            setSuccess("Logo ajouté avec succès");
            setTimeout(() => setSuccess(null), 3000);
    
            // Nettoyage après upload
            if (logoInputRef.current) logoInputRef.current.value = '';
            setProfileImageFile(null);
        } catch (error) {
            console.error("Erreur lors du téléchargement du logo :", error.message || error);
        } finally {
            setIsUploading(false);
        }
    };    
    

    const handleProfileImageDelete = async () => {
        try {
            const endpoint = isSuperAdmin && userIdFromParams
                ? `/superadmin/delete-profile-image/${userIdFromParams}`
                : "/delete-profile-image";
    
            await authAxios.delete(endpoint);
            setProfileImage("");
            setSuccess("Logo supprimé avec succès");
            setTimeout(() => setSuccess(null), 3000);
        } catch (error) {
            console.error("Erreur lors de la suppression du logo :", error.message || error);
        }
    };    

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);     


    return (
        <>
            <Navbar />
            <div className="fixed top-0 left-0 w-full z-50">
                {success && ( // Afficher le bandeau si success est vrai
                    <div className="bg-green-300 text-green-800 p-2 text-center font-bold">
                        {success}
                    </div>
                )}
            </div>
            <div>
                <div className="mt-5 flex justify-center space-x-8 items-start flex-wrap">
                    {/* Formulaire d'ajout de PDF à gauche */}
                    <div className="p-8 bg-white border shadow-md rounded-md w-full md:w-1/2 max-w-2xl">
                        <h1 className="text-3xl font-bold text-blue-500 mb-4 border-b-2 border-blue-300 pb-2">Ajouter un affichage légal</h1>
                        <form onSubmit={(e) => onSubmit(e, false)} className="mt-6">
        <textarea
            type="text"
            placeholder="Titre"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="w-full border border-gray-300 rounded-md p-2 mb-4"
        />
                            {errors.title && (
                                <div className="mt-2 text-sm text-red-600 flex items-center space-x-1 font-bold">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                                    </svg>
                                    <span>{errors.title}</span>
                                </div>
                            )}
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={(e) => setFile(e.target.files[0])}
                                required
                                className="w-full border border-gray-300 rounded-md p-2 mb-4"
                                ref={fileInputRef}
                            />
                            {errors.file && (
                                <div className="mt-2 text-sm text-red-600 flex items-center space-x-1 font-bold">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                                    </svg>
                                    <span>{errors.file}</span>
                                </div>
                            )}
                            <div className="mb-4">
                                <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">Date de début</label>
                                <input
                                    id="startDate"
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    required
                                    className="w-full border border-gray-300 rounded-md p-2 mb-4"
                                />
                                {errors.startDate && (
                                    <div className="mt-2 text-sm text-red-600 flex items-center space-x-1 font-bold">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                                        </svg>
                                        <span>{errors.startDate}</span>
                                    </div>
                                )}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">Date de fin</label>
                                <input
                                    id="endDate"
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    className="w-full border border-gray-300 rounded-md p-2 mb-4"
                                />
                                {errors.endDate && (
                                    <div className="mt-2 text-sm text-red-600 flex items-center space-x-1 font-bold">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                                        </svg>
                                        <span>{errors.endDate}</span>
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <select
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    className="w-full border border-gray-300 rounded-md p-2 mb-4"
                                >
                                    <option value="">Selectionner une catégorie</option>
                                    {categories.map((category) => (
                                        <option key={category._id} value={category._id}>{category.name}</option>
                                    ))}
                                </select>
                                {errors.category && (
                                    <div className="mt-2 text-sm text-red-600 flex items-center space-x-1 font-bold">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-10a1 1 0 10-2 0v4a1 1 0 002 0V8zm0 6a1 1 0 10-2 0v2a1 1 0 002 0v-2z" clipRule="evenodd" />
                                        </svg>
                                        <span>{errors.category}</span>
                                    </div>
                                )}
                            </div>

                            <button type="submit" className="w-full transition-colors hover:bg-blue-700 bg-blue-600 text-white p-2 rounded-md mb-4 font-bold">
                                Enregistrer et ajouter un nouveau document
                            </button>
                            <button
                                type="button"
                                className="w-full bg-green-600 text-white p-2 rounded-md mb-4 transition-colors hover:bg-green-700 font-bold"
                                onClick={(e) => onSubmit(e, true)}
                            >
                                Enregistrer et voir mes documents
                            </button>
                        </form>
                    </div>

                    {/* Bloc de gestion du logo à droite */}
                    <div className="p-8 bg-white border shadow-md rounded-md w-full md:w-1/2 max-w-xs flex flex-col justify-between" style={{ minHeight: '634.5px' }}>
                        {/* Affichage du nom de la mairie */}
                        {mairieName && (
                            <h2 className="text-3xl font-semibold text-green-600 text-center mb-2">{mairieName}</h2>
                        )}
                        <h2 className="text-2xl font-bold text-blue-500 mb-4 border-b-2 border-blue-300 pb-2">Mon Logo</h2>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleProfileImageChange}
                            className="w-full border border-gray-300 rounded-md p-2 mb-4"
                            ref={logoInputRef}
                        />

                        {profileImage && (
                            <div className="text-center">
                                <img
                                    src={profileImage.startsWith("blob:") ? profileImage : `${process.env.REACT_APP_API_URL}${profileImage}`}
                                    alt="Profil"
                                    className="w-[100px] h-auto object-cover mx-auto mb-4"
                                />
                                <button
                                    onClick={handleProfileImageDelete}
                                    className="text-white bg-red-600 hover:bg-red-700 px-4 py-2 rounded-md transition-colors mt-2 font-bold"
                                >
                                    Supprimer mon logo
                                </button>
                            </div>
                        )}

                        {(userId || mairieId) && (
                            <a
                                target="_blank"
                                href={`${fullUrl}${mairieId ? `/mairie/${mairieId}` : `/id/${userIdFromParams || userId}`}`}
                                className="inline-block bg-blue-600 text-white text-md font-semibold py-2 px-4 rounded-md shadow-md hover:bg-blue-700 transition-colors mt-auto mb-4"
                                style={{ marginBottom: '15px' }}
                            >
                                Ce qui est visible par le citoyen
                            </a>
                        )}
                    </div>
                </div>

                {/* Bloc de gestion des catégories */}
                <div className="flex justify-center mt-5">
                    <div className="bg-white border shadow-md rounded-md w-full md:w-1/2">
                        <div className="p-8">
                            <h2 className="text-3xl font-bold text-blue-500 mb-4 border-b-2 border-blue-300 pb-2">Gestion des catégories</h2>

                            <div className="mb-4">
        <textarea
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Nouvelle catégorie"
            className="w-full border border-gray-300 rounded-md p-2 w-9/10 md:w-3/4"
        />
                                {errors.addCategoryError && ( // Afficher l'erreur si elle existe
                                    <div className="text-red-500 text-sm mt-1 font-bold">{errors.addCategoryError}</div>
                                )}
                            </div>

                            <button
                                onClick={handleAddCategory}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Ajouter une catégorie
                            </button>
                        </div>

                        <div className="bg-white">
                            <ul className="">
                                {categories.map((category, index) => (
                                    <li
                                        key={category._id}
                                        className={`border-b border-gray-200 py-8 ${index % 2 === 0 ? 'bg-gray-100' : ''}`}
                                    >
                                        {/* Nom de la catégorie */}
                                        {editingCategoryId === category._id ? (
                                            <textarea
                                                type="text"
                                                value={categoryNameToUpdate}
                                                onChange={(e) => setCategoryNameToUpdate(e.target.value)}
                                                className="w-9/10 md:w-3/4 border border-gray-300 rounded-md p-2"
                                            />
                                        ) : (
                                            <span className="text-xl font-medium">{category.name}</span>
                                        )}

                                        {/* Boutons d'action */}
                                        <div className="mt-2 flex justify-center space-x-2">
                                            {editingCategoryId === category._id ? (
                                                <button
                                                    onClick={handleUpdateCategory}
                                                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                                >
                                                    Enregistrer
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => {
                                                        setEditingCategoryId(category._id);
                                                        setCategoryNameToUpdate(category.name);
                                                    }}
                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                                >
                                                    Modifier
                                                </button>
                                            )}

                                            <button
                                                onClick={() => handleDeleteCategory(category._id)}
                                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                            >
                                                Supprimer
                                            </button>
                                        </div>
                                        {categoryDeleteErrors[category._id] && (
                                            <div className="text-red-500 text-sm mt-1 font-bold">{categoryDeleteErrors[category._id]}</div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />               
        </>
    );
}

export default Home;
