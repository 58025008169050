import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Pagination from "./Pagination";
import axios from "axios";
import Footer from './Footer';
import { useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Navigate } from "react-router-dom";

function UploadedFiles() {
    const [allPdfs, setAllPdfs] = useState([]);
    const [pdfs, setPdfs] = useState(allPdfs || []);
    const [categories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const token = localStorage.getItem("token");
    const [errors, setErrors] = useState({}); // stockage des erreurs
    const [deleteSuccess, setDeleteSuccess] = useState(null); // stockage des success
    /// PAGINATION ///
    const [currentPage, setCurrentPage] = useState(1); // Page actuelle
    const [itemsPerPage, setItemsPerPage] = useState(30); // Nombre d'éléments par page
    // Calculer les indices des PDFs à afficher
    const indexOfLastPdf = currentPage * itemsPerPage;
    const indexOfFirstPdf = indexOfLastPdf - itemsPerPage;
    const currentPdfs = pdfs.slice(indexOfFirstPdf, indexOfLastPdf);
    // Calcul du nombre total de pages après filtrage utilisé pour caché la pagination si il ya que 1 page
    const totalPages = Math.ceil(pdfs.length / itemsPerPage);
    // Liste filtrée des PDFs
    const [filteredPdfs, setFilteredPdfs] = useState(pdfs);
    const [editingPdfId, setEditingPdfId] = useState(null); // ID du PDF en cours d'édition
    //superadmin

    // Fonction pour extraire le rôle depuis le token
    const getUserRoleFromToken = (token) => {
        try {
            if (!token) return null; // Vérifier si le token existe
            const decoded = jwtDecode(token); // Décoder le JWT
            return decoded.role || null; // Retourner le rôle (ex: "superadmin")
        } catch (error) {
            console.error(" Erreur lors du décodage du token :", error);
            return null;
        }
    };
    const userRole = getUserRoleFromToken(token); // Récupérer le rôle depuis le token
    console.log("🛠️ Token stocké :", token);
    console.log("🔑 Rôle décodé depuis le token :", userRole);
    console.log("🛠️ Token stocké :", token);
    console.log("🔑 Rôle stocké :", userRole);
    const { userId } = useParams(); // Récupérer userId depuis l'URL
    console.log("👤 userId depuis useParams :", userId);

    const authAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL+'/api',
        headers: {
            Authorization: token ? `Bearer ${token}` : null,
        },
    });


    const fetchPdfs = async () => {
        try {
            let result;
            
            if (userRole === "superadmin" && userId) {
                console.log(` Superadmin - Chargement des fichiers de l'utilisateur ${userId}`);
                result = await authAxios.get(`/superadmin/get-files/${userId}`);
            } else {
                console.log(" Utilisateur normal - Chargement de ses propres fichiers");
                result = await authAxios.get('/get-files');
            }
    
            if (result.data && Array.isArray(result.data.data)) {
                console.log(" PDFs récupérés avec succès :", result.data.data);
                setAllPdfs(result.data.data);
                setPdfs(result.data.data);
            } else {
                console.error(" Structure inattendue des données reçues", result.data);
                setAllPdfs([]);
                setPdfs([]);
            }
        } catch (error) {
            console.error(" Erreur lors de la récupération des PDFs", error);
            setAllPdfs([]);
            setPdfs([]);
        }
    };
    
    

// ✅ Charge les PDFs immédiatement après le premier rendu et chaque fois que `userId` change
useEffect(() => {
    console.log(" Chargement des PDFs pour userId :", userId);
    fetchPdfs();
}, [userId]); // Recharge les PDFs si on change d'utilisateur sélectionné

// S'assure que les PDFs sont bien mis à jour avant toute modification
useEffect(() => {
    console.log(" PDFs mis à jour :", allPdfs);
    setPdfs(allPdfs);
}, [allPdfs]);


    const loadCategoriesAndPdfs = async (userId) => {
        try {
            let categoriesResult, pdfsResult;
    
            if (userId) { 
                categoriesResult = await authAxios.get(`/superadmin/get-categories/${userId}`);
                pdfsResult = await authAxios.get(`/superadmin/get-files/${userId}`);
            } else { 
                categoriesResult = await authAxios.get("/get-categories");
                pdfsResult = await fetchPdfs();
            }
    
            console.log("➡️ Réponse complète de get-categories :", categoriesResult?.data);
            console.log("➡️ Réponse complète de get-files :", pdfsResult?.data);
    
            // Vérification si les données existent
            const categories = categoriesResult?.data?.categories ?? [];
            const pdfs = pdfsResult?.data?.data ?? [];
    
            console.log(" Catégories après sécurisation:", categories);
            console.log(" PDFs après sécurisation:", pdfs);
    
            if (!Array.isArray(categories)) {
                console.error(" ERREUR: categories n'est pas un tableau !");
                setCategories([]);
            } else {
                // Trier les catégories (si besoin)
                const sortedCategories = categories.sort((a, b) => a.name.localeCompare(b.name));
                setCategories(sortedCategories);
            }
    
            if (!Array.isArray(pdfs)) {
                console.error("ERREUR: pdfs n'est pas un tableau !");
                setPdfs([]);
            } else {
                console.log("Avant mise à jour de pdfs :", pdfs);
                setPdfs(pdfs);
                console.log("Après mise à jour de pdfs :", pdfs);
            }            
        } catch (error) {
            console.error("Erreur lors du chargement des catégories et des PDFs", error);
        }
    };    

    useEffect(() => {
        console.log("🔄 Chargement des catégories et PDFs pour userId :", userId);
        loadCategoriesAndPdfs(userId);
        fetchPdfs(userId); // Appel en mode superadmin
    }, [userId]);
    
    // Récupérer les catégories depuis le serveur
    const getCategories = async () => {
        try {
            const result = await authAxios.get("/get-categories");
            const sortedCategories = result.data.categories.sort((a, b) =>
                a.name.localeCompare(b.name),
            );
            setCategories(sortedCategories); // Mettre à jour l'état avec les catégories triées
        } catch (error) {
            console.error("Erreur lors de la récupération des catégories", error);
        }
    };

    // Mettre à jour un PDF avec les nouvelles informations
    const updatePdf = async (pdfId, updatedData, newFile = null) => {
        if (!/[a-zA-Z0-9]/.test(updatedData.title)) {
            setErrors({ [`${pdfId}-title`]: "Veuillez ajouter un titre" });
            return;
        }
    
        console.log("🔍 Tentative de mise à jour du PDF :", pdfId);
        console.log("📝 Données envoyées :", updatedData);
    
        // Vérifier que la catégorie est bien sélectionnée
        if (!updatedData.category || !updatedData.category._id) {
            console.warn("⚠️ Aucune catégorie sélectionnée !");
        } else {
            console.log("✅ Catégorie sélectionnée :", updatedData.category._id);
        }
    
        try {
            const formData = new FormData();
            formData.append("title", updatedData.title);
            formData.append("startDate", updatedData.startDate ? new Date(updatedData.startDate).toISOString() : "");
            formData.append("endDate", updatedData.endDate ? new Date(updatedData.endDate).toISOString() : "");
    
            if (updatedData.category && updatedData.category._id) {
                formData.append("category", updatedData.category._id);
            }
    
            if (newFile) {
                formData.append("file", newFile);
            }
    
            console.log("📡 Données envoyées au serveur :", {
                title: formData.get("title"),
                startDate: formData.get("startDate"),
                endDate: formData.get("endDate"),
                category: formData.get("category"),
            });
    
            const updateRoute = userId ? `/superadmin/update-pdf/${pdfId}` : `/update-pdf/${pdfId}`;
            const result = await authAxios.put(updateRoute, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
    
            if (result.data.status === "ok") {
                console.log("✅ Mise à jour réussie !");
                setErrors({ updatePdfSuccess: "PDF mis à jour avec succès" });
    
                // 🔥 Ajout : Vérifier immédiatement la catégorie après mise à jour
                await fetchPdfs();
                setEditingPdfId(null);
            } else {
                console.error("❌ Échec de la mise à jour :", result.data);
                setErrors({ updatePdf: "Échec de la mise à jour du PDF." });
            }
        } catch (error) {
            console.error("⛔ Erreur lors de la mise à jour du PDF :", error);
            setErrors({ updatePdf: error.response?.data?.message || "Une erreur est survenue." });
        }
    };
    
    
    // Supprimer un PDF
    const deletePdf = async (pdfId) => {
        try {
    // Utiliser la route SuperAdmin si userId est présent dans l'URL
        const deleteRoute = userId ? `/superadmin/delete-pdf/${pdfId}` : `/delete-pdf/${pdfId}`;
        const result = await authAxios.delete(deleteRoute);
            if (result.data.status === "ok") {
                setErrors({});
                setPdfs(pdfs.filter((pdf) => pdf._id !== pdfId));
                setDeleteSuccess("PDF supprimé avec succès");
                setTimeout(() => {
                    setDeleteSuccess(null); // Masquer le message après un délai
                }, 3000); // 3 secondes
            } else {
                setErrors({ deletePdf: "Échec de la suppression du PDF." });
            }
        } catch (error) {
            console.error("Erreur lors de la suppression du PDF", error);
            setErrors({ deletePdf: "Une erreur est survenue lors de la suppression." });
        }
    };



    // Gérer la modification des champs (titre, dates, catégorie, fichier PDF)
    const handleChange = (e, pdfId, field) => {
        const value = e.target.value;

        const updatedPdfs = pdfs.map((pdf) => {
            if (pdf._id === pdfId) {
                const updatedPdf = { ...pdf, [field]: value };

                if (
                    field === "endDate" &&
                    new Date(updatedPdf.startDate) > new Date(value)
                ) {
                    setErrors({ endDate: "La date de fin ne peut pas être antérieure à la date de début." });
                    return pdf; // Ne pas appliquer la modification si la validation échoue
                }

                if (
                    field === "startDate" &&
                    updatedPdf.endDate &&
                    new Date(value) > new Date(updatedPdf.endDate)
                ) {
                    setErrors({ startDate: "La date de début ne peut pas être postérieure à la date de fin." });
                    return pdf; // Ne pas appliquer la modification si la validation échoue
                }
                if (field === "category") {
                    const selectedCategory = categories.find((cat) => cat._id === value);
                    if (selectedCategory) {
                        updatedPdf.category = selectedCategory; // Stocker l'objet complet
                    }
                }
                // Réinitialiser l'erreur correspondante au champ modifié SEULEMENT si la date est valide
                setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
                return updatedPdf;
            }

            return pdf;
        });
        setPdfs(updatedPdfs);
    };

    const handleFileChange = (e, pdfId) => {
        const file = e.target.files[0];
        if (file) {
            const updatedPdfs = pdfs.map((pdf) =>
                pdf._id === pdfId ? { ...pdf, pdfFile: file } : pdf,
            );
            setPdfs(updatedPdfs);
        }
    };

    // Filtrer les PDFs en fonction de la recherche et des filtres
    const filterPdfs = () => {
        let filteredPdfs = [...allPdfs];
    
        if (searchQuery) {
            filteredPdfs = filteredPdfs.filter((pdf) =>
                pdf.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                pdf.pdf.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
    
        if (selectedCategory) {
            filteredPdfs = filteredPdfs.filter(
                (pdf) => pdf.category._id === selectedCategory
            );
        }
    
        if (selectedDate) {
            const selected = new Date(selectedDate);
            filteredPdfs = filteredPdfs.filter((pdf) => {
                const startDate = new Date(pdf.startDate);
                const endDate = new Date(pdf.endDate);
                return selected >= startDate && selected <= endDate;
            });
        }
    
        //  Applique le tri (certificats en dernier + date croissante)
        filteredPdfs.sort((a, b) => {
            if (a.certificat && !b.certificat) {
                return 1; 
            } else if (!a.certificat && b.certificat) {
                return -1; 
            }
            return new Date(a.createdAt) - new Date(b.createdAt);
        });
    
        console.log("📌 Ordre des PDFs après tri :", filteredPdfs.map(pdf => ({
            title: pdf.title,
            certificat: pdf.certificat ? "✅" : "❌",
            createdAt: pdf.createdAt
        })));
    
        // Crée une nouvelle copie pour forcer la mise à jour du state
        setPdfs([...filteredPdfs]); 
        setCurrentPage(1);
    };
    


    // Fonction pour gérer l'ouverture/fermeture du dropdown
    const toggleDropdown = (pdfId) => {
        setPdfs((prevPdfs) =>
            prevPdfs.map((pdf) =>
                pdf._id === pdfId ? { ...pdf, isOpen: !pdf.isOpen } : pdf,
            ),
        );
    };

    const handleEditClick = (pdfId) => {
        setEditingPdfId(pdfId);
    };

    const handleCancelClick = () => {
        setEditingPdfId(null);
    };

    const showPdf = (pdf) => {
        window.open(process.env.REACT_APP_API_URL+`/assets/pdf/${pdf}`, "_blank", "noreferrer");
    };

    // Mettre à jour les PDFs filtrés chaque fois qu'un critère change
    useEffect(() => {
        // Charge les PDFs uniquement au montage du composant
        const loadPdfs = async () => {
            console.log("Chargement des PDFs via fetchPdfs...");
            await fetchPdfs(); // fetchPdfs met à jour `allPdfs` dans le contexte
        };
        loadPdfs();
    }, []); // Appelé uniquement une fois

    useEffect(() => {
        // Filtre les PDFs une fois qu'ils sont chargés ou que les critères changent
        if (allPdfs.length > 0) {
            filterPdfs();
        }
    }, [allPdfs, searchQuery, selectedCategory, selectedDate]); // Réexécute le filtrage si les critères ou les PDFs changent

    useEffect(() => {
        // Appliquer la pagination après avoir filtré les PDFs
        const indexOfLastPdf = currentPage * itemsPerPage;
        const indexOfFirstPdf = indexOfLastPdf - itemsPerPage;
        const currentPdfs = pdfs.slice(indexOfFirstPdf, indexOfLastPdf); // Slice sur les PDFs filtrés
    }, [pdfs, currentPage, itemsPerPage]); // Appliquer la pagination à chaque changement de page ou de PDFs filtré

    useEffect(() => {
        console.log("📌 Catégories affichées dans le filtre :", categories);
    }, [categories]);    

    useEffect(() => {
        if (allPdfs.length > 0) {
            console.log("🔄 Mise à jour des PDFs après récupération :", allPdfs);
            setPdfs(allPdfs);
        }
    }, [allPdfs]);

    useEffect(() => {
        if (Object.keys(errors).length > 0 || deleteSuccess) {
            const timeout = setTimeout(() => {
                setErrors({});
                setDeleteSuccess(null);
            }, 3000); // 3 secondes
    
            return () => clearTimeout(timeout); // Nettoyer le timeout si le composant est démonté ou si les erreurs/success changent avant la fin du délai
        }
    }, [errors, deleteSuccess]);
    
    return (
        <div>
            <Navbar />

            {/* Bandeau pour les messages d'erreur */}
            <div className="fixed top-0 left-0 w-full z-50 font-bold">
                {errors.updatePdfSuccess && (
                    <div className="bg-green-300 text-green-800 p-2 text-center">
                        {errors.updatePdfSuccess}
                    </div>
                )}
                {errors.updatePdf && (
                    <div className="bg-red-200 text-red-800 p-2 text-center">
                        {errors.updatePdf}
                    </div>
                )}
                {deleteSuccess && ( // Afficher le message de succès si présent
                    <div className="bg-green-300 text-green-800 p-2 text-center">
                        {deleteSuccess}
                    </div>
                )}
            </div>

            {/* Conteneur des champs de filtrage */}
            <div className="mb-4 flex gap-4 pt-4 pl-4 pr-4">
                {/* Champ de recherche */}
                <div className="flex-1">
                    <input
                        type="text"
                        placeholder="Rechercher par titre ou nom de fichier"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full border border-gray-300 rounded-md p-2"
                    />
                </div>

                {/* Dropdown pour le filtrage par catégorie */}
                <div className="flex-1">
                    <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="w-full border border-gray-300 rounded-md p-2"
                    >
                        <option value="">Trier par catégorie</option>
                        {categories.map((category) => (
                            <option key={category._id} value={category._id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>


                {/* Filtre de date unique */}
                <div className="flex-1">
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        className="w-full border border-gray-300 rounded-md p-2"
                        placeholder="Filtrer par date"
                    />
                </div>
            </div>

            {/* Liste des PDFs */}
            <div className="px-[30px] lg:px-[4rem] container-pdf-bo">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 pb-[25px]">
                {console.log("📜 PDFs à afficher :", pdfs)} 
                    {currentPdfs.map((data, index) => (
                        <div
                            className={`border border-gray-300 shadow-md hover:shadow-xl rounded-lg transition-transform transform hover:-translate-y-2 bg-white flex flex-col justify-between overflow-hidden group ${editingPdfId === data._id ? 'h-auto' : 'h-fit'}`}
                            key={index}
                        >
                            {editingPdfId === data._id ? (
                                // Formulaire d'édition
                                <div className="mt-0 p-4 bg-gray-100 border-t border-gray-300 rounded-b-lg mt-0">
                                    {errors.updatePdf && <div className="text-red-500">{errors.updatePdf}</div>}
                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold">Titre</label>
                                        <textarea
                                            type="text"
                                            value={data.title || ""}
                                            onChange={(e) => handleChange(e, data._id, "title")}
                                            className="w-full border border-gray-300 rounded-md p-2 mt-1"
                                        />
                                        {errors[`${data._id}-title`] && (
                                            <div className="text-red-500">
                                                {errors[`${data._id}-title`]}
                                            </div>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold">
                                            Date de début
                                        </label>
                                        <input
                                            type="date"
                                            value={
                                                data.startDate
                                                    ? new Date(data.startDate).toISOString().split("T")[0]
                                                    : ""
                                            }
                                            onChange={(e) => handleChange(e, data._id, "startDate")}
                                            className="w-full border border-gray-300 rounded-md p-2 mt-1"
                                        />
                                        {errors.startDate && <div className="text-red-500">{errors.startDate}</div>}
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold">
                                            Date de fin
                                        </label>
                                        <input
                                            type="date"
                                            value={
                                                data.endDate
                                                    ? new Date(data.endDate).toISOString().split("T")[0]
                                                    : ""
                                            }
                                            onChange={(e) => handleChange(e, data._id, "endDate")}
                                            className="w-full border border-gray-300 rounded-md p-2 mt-1"
                                        />
                                        {errors.endDate && <div className="text-red-500">{errors.endDate}</div>}
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold">
                                            Catégorie
                                        </label>
                                        <select
                                            value={data.category ? data.category._id : ""}
                                            onChange={(e) => handleChange(e, data._id, "category")}
                                            className="w-full border border-gray-300 rounded-md p-2 mt-1"
                                        >
                                            <option value="">Sélectionner une catégorie</option>
                                            {categories.map((category) => (
                                                <option key={category._id} value={category._id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>


                                        {errors[`${data._id}-category`] && (
                                            <div className="text-red-500">
                                                {errors[`${data._id}-category`]}
                                            </div>
                                        )}

                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-semibold">
                                            Modifier le fichier PDF
                                        </label>
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            onChange={(e) => handleFileChange(e, data._id)}
                                            className="w-full border border-gray-300 rounded-md p-2 mt-1"
                                        />
                                    </div>

                                    <button
                                        onClick={() =>
                                            updatePdf(
                                                data._id,
                                                {
                                                    title: data.title,
                                                    startDate: data.startDate,
                                                    endDate: data.endDate,
                                                    category: data.category,
                                                },
                                                data.pdfFile,
                                            )
                                        }
                                        className="transition-colors hover:bg-green-700 w-full bg-green-600 text-white p-2 rounded-md mt-2"
                                    >
                                        Mettre à jour
                                    </button>

                                    <button
                                        onClick={handleCancelClick}
                                        className="transition-colors hover:bg-gray-400 w-full bg-gray-300 text-black p-2 rounded-md mt-4"
                                    >
                                        Annuler
                                    </button>
                                </div>
                            ) : (
                                // Carte PDF
                                <div>
                                    <div className="p-4 flex flex-col">
                                        <h6 className="font-semibold text-lg text-gray-800 truncate mb-2">
                                            {data.title || "Titre manquant"}
                                        </h6>
                                        <h6 className="text-gray-600 line-clamp-1">
                                            {data.pdf.replace(/^\d+/, "")} {/* Supprimer les numéros */}
                                        </h6>

                                        {data.category && (
                                        <h6 className="text-teal-400">
                                            {categories.find((category) => category._id === data.category._id)?.name || "Chargement..."}
                                        </h6>
                                        )}


                                        {data.startDate && data.endDate && (
                                            <h6 className="text-sm text-gray-500">
                                                du {new Date(data.startDate).toLocaleDateString()} au{" "}
                                                {new Date(data.endDate).toLocaleDateString()}
                                            </h6>
                                        )}
                                    </div>

                                    <div className="bg-gray-50 p-3 border-t border-gray-200 flex flex-col justify-between mt-auto">
                                        <button
                                            onClick={() => showPdf(data.pdf)}
                                            className="transition-colors hover:bg-blue-600 bg-blue-500 text-white rounded-md p-2 text-center mb-2"
                                        >
                                            Voir le PDF
                                        </button>
                                        {!data.certificat && (
                                            <>
                                                <button
                                                    onClick={() => handleEditClick(data._id)} // Appeler handleEditClick
                                                    className="transition-colors hover:bg-gray-400 bg-gray-300 text-black rounded-md p-2"
                                                >
                                                    Modifier
                                                </button>
                                                <button
                                                    onClick={() => deletePdf(data._id)} // Bouton Supprimer
                                                    className="transition-colors hover:bg-red-600 mt-2 bg-red-500 text-white rounded-md p-2"
                                                >
                                                    Supprimer
                                                </button>
                                            </>
                                        )}
                                        {data.certificat && (
                                            <button
                                                onClick={() => window.open(`https://affichage-legal-api.mymairie.fr/${data.certificat}`, "_blank", "noreferrer")}
                                                className="transition-colors hover:bg-green-600 bg-green-500 text-white rounded-md p-2 text-center mt-2"
                                            >
                                                Voir le certificat
                                            </button>
                                        )}
                                        {console.log(data.certificat)}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <Pagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={pdfs.length}
                paginate={setCurrentPage}
                setItemsPerPage={setItemsPerPage}
            />
            <Footer />  
        </div>
    ); 
}

export default UploadedFiles;