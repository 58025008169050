import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "./Footer";
import jwtDecode from 'jwt-decode';


function SuperAdminValneo() {
    const [users, setUsers] = useState([]);
    const [success, setSuccess] = useState(null);
    const [errors, setErrors] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [editingUser, setEditingUser] = useState(null); // Utilisateur en cours de modification
    const [formData, setFormData] = useState({ name: "", email: "", password: "" });
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [superadmins, setSuperadmins] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null); // stocker la mairie pour le userid de la navbar

    const authAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL + "/api",
        headers: {
            Authorization: token ? `Bearer ${token}` : null,
        },
    });

    // fetch les mairies
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                console.log("Tentative de récupération des utilisateurs.");
                const result = await authAxios.get("/superadmin/users");
                
                // Filtrer uniquement les utilisateurs avec le rôle "user"
                const filteredUsers = result.data.users.filter(user => user.role === "user");
    
                setUsers(filteredUsers || []);
            } catch (error) {
                console.error("Erreur lors de la récupération des utilisateurs :", error.message || error);
                setErrors("Erreur lors de la récupération des utilisateurs.");
                setTimeout(() => setErrors(null), 3000);
            }
        };
    
        fetchUsers();
    }, []);

    // fetch les superadmin
    useEffect(() => {
        const fetchSuperadmins = async () => {
            try {
                console.log("Tentative de récupération des superadmins.");
                const result = await authAxios.get("/superadmin/users");
    
                // Filtrer uniquement les utilisateurs avec le rôle "superadmin"
                const filteredSuperadmins = result.data.users.filter(user => user.role === "superadmin");
    
                setSuperadmins(filteredSuperadmins || []);
            } catch (error) {
                console.error("Erreur lors de la récupération des superadmins :", error.message || error);
                setErrors("Erreur lors de la récupération des superadmins.");
                setTimeout(() => setErrors(null), 3000);
            }
        };
    
        fetchSuperadmins();
    }, []);

    // Supprimer un utilisateur
    const handleDeleteUser = async () => {
        try {
            const response = await authAxios.delete(`/superadmin/users/${userToDelete}`);
    
            // ✅ Vérifie si l'utilisateur supprimé est celui connecté
            const currentUserId = jwtDecode(localStorage.getItem('token')).id; 
    
            if (userToDelete === currentUserId) {
                console.log("Utilisateur connecté supprimé, déconnexion immédiate...");
                localStorage.removeItem('token'); // Supprime le token
                localStorage.setItem('forceLogout', "true"); // Ajoute un flag pour forcer la déconnexion
                window.location.href = "/"; // Redirige vers la page de connexion
                return;
            }
    
            // 🔹 Met à jour la liste des utilisateurs en enlevant celui supprimé
            setUsers(users.filter((user) => user._id !== userToDelete));
            setSuccess("Mairie supprimée avec succès.");
            setTimeout(() => setSuccess(null), 3000);
        } catch (error) {
            console.error("Erreur lors de la suppression de la mairie :", error.message || error);
            setErrors("Erreur lors de la suppression de la mairie.");
            setTimeout(() => setErrors(null), 3000);
        } finally {
            setShowConfirmation(false);
            setUserToDelete(null);
        }
    };
    

    const handleDeleteConfirmation = (userId) => {
        setUserToDelete(userId);
        setShowConfirmation(true);
    };

    // Ouvrir le formulaire de modification
    const handleEditUser = (user) => {
        setEditingUser(user._id);
        setFormData({
            name: user.name || "",
            email: user.email || "",
            password: "" // Laisser vide pour ne pas afficher l'ancien mot de passe
        });
    };

    // Fermer le formulaire de modification
    const handleCancelEdit = () => {
        setEditingUser(null);
        setFormData({ name: "", email: "", password: "" });
    };

    // Gérer les changements de formulaire
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Mettre à jour un utilisateur
    const handleUpdateUser = async (e, userId) => {
        e.preventDefault();
        setSuccess(null);
        setErrors(null);

        // Vérification de l'email avant envoi
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (formData.email && !emailRegex.test(formData.email)) {
            setErrors("L'adresse email est invalide.");
            setTimeout(() => setErrors(null), 3000);
            return;
        }

        try {
            await authAxios.put(`/superadmin/users/${userId}`, formData);
            setSuccess("Utilisateur mis à jour avec succès !");
            setTimeout(() => setSuccess(null), 3000);
            
            // Mettre à jour l'affichage sans recharger la page
            setUsers(users.map(user => user._id === userId ? { ...user, ...formData } : user));
            
            // Fermer le formulaire
            handleCancelEdit();
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error.message || error);
            
            if (error.response && error.response.data && error.response.data.message) {
                setErrors(error.response.data.message);
            } else {
                setErrors("Une erreur s'est produite lors de la mise à jour.");
            }
        }        
    };
    
    // Logique pour Ajouter une Mairie
    const [mairieOptions, setMairieOptions] = useState([]);
    const [addMairieForm, setAddMairieForm] = useState({ name: "", email: "", password: "", mairieId: null });

    useEffect(() => {
        const fetchMairieOptions = async () => {
            try {
                const response = await fetch("https://mymairie.fr/backoffice/apis/villes/");
                const data = await response.json();
                const dataArray = data.map((ville) => ({ id: ville.mairie_id, value: ville.ville }));
                setMairieOptions(dataArray);
            } catch (error) {
                console.error("Erreur lors de la récupération des mairies :", error);
            }
        };

        fetchMairieOptions();
    }, []);

    const handleMairieChange = (e) => {
        const selectedOption = e.target.value === "" ? null : JSON.parse(e.target.value);
        setAddMairieForm((prevData) => ({
            ...prevData,
            mairieId: selectedOption ? selectedOption.id : null,
            name: selectedOption ? selectedOption.value : "",
        }));
    };

    const handleAddMairieChange = (e) => {
        const { name, value } = e.target;
        setAddMairieForm((prevData) => ({ ...prevData, [name]: value }));
    };

    const resetMairieSelection = () => {
        setAddMairieForm({ mairieId: null, name: "", email: "", password: "" });
    };

    const handleAddMairieSubmit = async (event) => {
        event.preventDefault();
    
        // Vérification des champs obligatoires
        if (!addMairieForm.name && !addMairieForm.mairieId) {
            setErrors("Veuillez renseigner une mairie manuellement ou en sélectionner une dans la liste.");
            setTimeout(() => setErrors(null), 3000);
            return;
        }
    
        if (!addMairieForm.email) {
            setErrors("Email requis.");
            setTimeout(() => setErrors(null), 3000);
            return;
        }
    
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
        if (!emailRegex.test(addMairieForm.email)) {
            setErrors("Format d'email invalide. Veuillez saisir une adresse valide.");
            setTimeout(() => setErrors(null), 3000);
            return;
        }
    
        if (!addMairieForm.password) {
            setErrors("Mot de passe requis.");
            setTimeout(() => setErrors(null), 3000);
            return;
        }
    
        // Récupérer le token du SuperAdmin
        const token = localStorage.getItem("token");
    
        if (!token) {
            setErrors("Accès refusé. Vous devez être connecté en tant que SuperAdmin.");
            return;
        }
    
        try {
            // Vérifier que l'utilisateur est bien SuperAdmin
            const decodedToken = JSON.parse(atob(token.split(".")[1])); 
            if (decodedToken.role !== "superadmin") {
                setErrors("Accès refusé. Seuls les SuperAdmins peuvent créer une mairie.");
                return;
            }
        } catch (error) {
            console.error("Erreur lors du décodage du token :", error);
            setErrors("Erreur d'authentification. Veuillez vous reconnecter.");
            return;
        }
    
        // Vérifier si le mairieId existe déjà dans la liste des utilisateurs
        if (addMairieForm.mairieId !== null) {
            const mairieExists = users.some(user => user.mairieId === addMairieForm.mairieId);
            if (mairieExists) {
                setErrors("Cette mairie est déjà enregistrée.");
                setTimeout(() => setErrors(null), 3000);
                return;
            }
        }
    
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/superadmin/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, // Authentification requise
                },
                body: JSON.stringify(addMairieForm),
            });
    
            const data = await response.json();
            console.log("Réponse API :", data);
    
            if (response.ok) { 
                setSuccess("Mairie ajoutée avec succès !");
                setTimeout(() => setErrors(null), 3000);
                // Ajouter la nouvelle mairie à la liste des utilisateurs sans recharger la page
                const newMairie = data.newMairie; // récuperer l'objet newmaiirie du backend
                if (!newMairie) {
                console.error("Erreur : newMairie non trouvée dans la réponse API", data);
                return;
                }
                // Mettre à jour la liste des utilisateurs sans recharger la page
                setUsers(prevUsers => [...prevUsers, newMairie]);
                setTimeout(() => setSuccess(null), 3000);
                resetMairieSelection();
            } else {
                setErrors(data.message || "Une erreur s'est produite."); 
                setTimeout(() => setErrors(null), 3000);
            }
        } catch (error) {
            console.error("Erreur serveur :", error);
            setErrors("Erreur serveur.");
            setTimeout(() => setErrors(null), 3000);
        }
    };    

    // redirigé sur la page home avec userid
    const handleAccess = async (userId) => {
        try {
            // Vérifier si l'utilisateur est bien un SuperAdmin avant la redirection
            const response = await authAxios.get(`/superadmin/user/${userId}`);
    
            if (response.status === 200) {
                sessionStorage.setItem("selectedUserId", userId); // Stocke l'ID pour la navbar
                setSelectedUserId(userId); // Met à jour l'état local
                
                navigate(`/accueil/${userId}`);  // Rediriger seulement si l'accès est autorisé
            }
        } catch (error) {
            console.error("Accès refusé :", error);
            setErrors("Vous n'êtes pas autorisé à accéder à cet utilisateur.");
            setTimeout(() => setErrors(null), 3000);
        }
    };    

    useEffect(() => {
        const storedUserId = sessionStorage.getItem("selectedUserId");
        if (storedUserId) {
            setSelectedUserId(storedUserId);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token'); // Supprime le token
        sessionStorage.removeItem('selectedUserId'); // Supprime l'utilisateur sélectionné (superadmin)
        navigate('/'); // Redirection vers la page de connexion
        window.location.reload(); // Recharge la page pour appliquer les restrictions des routes protégées
    };

    return (
        <>      <div className="bg-blue-500">
                <div className="w-full bg-blue-500 py-4 px-6 flex flex-col sm:flex-row items-center relative">
                <h1 className="text-white font-bold text-xl sm:absolute sm:left-1/2 sm:transform sm:-translate-x-1/2 text-center">
                    ADMINISTRATION
                </h1>
                <button 
                    onClick={handleLogout} 
                    className="mt-2 sm:mt-0 sm:ml-auto bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Se déconnecter
                </button>
               </div>
                </div>
            <div className="container mx-auto px-4 py-8">

                {/* Notifications de succès et d'erreur en fixed en haut de l'écran */}
                {(success || errors) && (
                    <div className="fixed top-0 left-0 w-full flex justify-center items-center z-50">
                        <div className={`mt-4 p-3 rounded-md shadow-md text-white text-center w-[90%] sm:w-auto sm:px-6 sm:py-3 
                            ${success ? 'bg-green-500' : 'bg-red-500'}`}>
                            {success || errors}
                        </div>
                    </div>
                )}

                {/* Confirmation de suppression */}
                {showConfirmation && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                            <h2 className="text-lg font-semibold mb-4">Confirmation</h2>
                            <p className="mb-4">    Êtes-vous sûr de vouloir supprimer la mairie <strong>{users.find(user => user._id === userToDelete)?.name || "cet utilisateur"}</strong> ? Cette action est irréversible !</p>
                            <div className="flex justify-center space-x-4">
                                <button onClick={() => setShowConfirmation(false)} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded">Annuler</button>
                                <button onClick={handleDeleteUser} className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded">Confirmer</button>
                            </div>
                        </div>
                    </div>
                )}

                <div class="flex flex-col sm:flex-row sm:gap-5 mb-6 sm:justify-center items-center">

                {/* Bloc pour "Ajouter une Mairie" */}
                <div className="bg-white shadow-lg rounded-lg p-8 w-full sm:w-[40%] sm:max-w-md">
                    <h2 className="text-2xl font-bold text-blue-500 text-center mb-6">Ajouter une Mairie</h2>

                    <form onSubmit={handleAddMairieSubmit}>
                        {/* Champ pour saisir la mairie manuellement */}
                        <input
                            type="text"
                            name="name"
                            value={addMairieForm.name}
                            onChange={(e) => {
                                setAddMairieForm({ ...addMairieForm, name: e.target.value, mairieId: null });
                            }}
                            placeholder="Nom de la mairie"
                            className="w-full p-2 border rounded-md mb-4"
                            disabled={addMairieForm.mairieId !== null} // Bloqué si une mairie est sélectionnée
                        />

                        <span className="block text-center mb-3">OU</span>

                        {/* Sélection dans le dropdown */}
                        <div className="relative">
                            <select
                                name="mairieId"
                                onChange={handleMairieChange}
                                value={
                                    addMairieForm.mairieId
                                        ? JSON.stringify({ id: addMairieForm.mairieId, value: addMairieForm.name })
                                        : ""
                                }
                                className="w-full p-2 border rounded-md mb-4"
                            >
                                <option value="">Sélectionnez une mairie</option>
                                {mairieOptions.map((mairie) => (
                                    <option key={mairie.id} value={JSON.stringify(mairie)}>
                                        {mairie.value}
                                    </option>
                                ))}
                            </select>

                            {/* Bouton "X" pour réinitialiser uniquement le champ mairie et le dropdown */}
                            {addMairieForm.mairieId !== null && (
                                <button
                                    type="button"
                                    onClick={() => setAddMairieForm({ ...addMairieForm, mairieId: null, name: "" })}
                                    className="absolute right-[1.5rem] top-2 text-red-500 hover:text-red-700"
                                >
                                    ❌
                                </button>
                            )}
                        </div>

                        {/* Champ Email */}
                        <input
                            type="email"
                            name="email"
                            value={addMairieForm.email}
                            onChange={handleAddMairieChange}
                            placeholder="Email"
                            className="w-full p-2 border rounded-md mb-4"
                        />

                        {/* Champ Mot de Passe */}
                        <input
                            type="password"
                            name="password"
                            value={addMairieForm.password}
                            onChange={handleAddMairieChange}
                            placeholder="Mot de passe"
                            className="w-full p-2 border rounded-md mb-4"
                        />

                        {/* Bouton de soumission */}
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
                        >
                            Ajouter
                        </button>
                    </form>
                </div>

                {/* Formulaire de modification d'un user*/}
                {editingUser && (
            <div className="bg-white shadow-lg rounded-lg p-8 w-full sm:w-[50%] md:w-[40%] lg:w-[30%] sm:max-w-md sm:self-start">
                <h2 className="text-xl text-blue-500 font-semibold mb-4">Modifier l'utilisateur</h2>
                <form onSubmit={(e) => handleUpdateUser(e, editingUser)}>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} className="w-full p-2 border mb-2" placeholder="Mairie" />
                    <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full p-2 border mb-2" placeholder="Email" />
                    <input type="password" name="password" value={formData.password} onChange={handleChange} className="w-full p-2 border mb-2" placeholder="Nouveau mot de passe" />
                    <div className="flex space-x-4 mt-4">
                        <button type="submit" className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded">Enregistrer</button>
                        <button onClick={handleCancelEdit} className="hover:bg-gray-400 bg-gray-300 text-black px-4 py-2 rounded">Annuler</button>
                    </div>
                </form>
            </div>
                )}
            </div>

                {/* Table des utilisateurs */}
                <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                <h2 className="text-2xl font-bold text-blue-500 text-center mb-4">Liste des mairies</h2>
                    <table className="min-w-full table-auto">
                        <thead className="bg-gray-100 text-gray-700">
                            <tr>
                                <th className="py-3 px-4 text-center">Mairie</th>
                                <th className="py-3 px-4 text-center">Email</th>
                                <th className="py-3 px-4 text-center">MairieID</th>
                                <th className="py-3 px-4 text-center">UserID</th>
                                <th className="py-3 px-4 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700">
                            {users && users.length > 0 ? (
                                users.map((user) => (
                                    <tr key={user._id} className="border-b hover:bg-gray-50">
                                        <td className="py-3 px-4">{user.name || "Mairie non défini"}</td>
                                        <td className="py-3 px-4">{user.email || "Email non défini"}</td>
                                        <td className="py-3 px-4 text-center">{user.mairieId || "Mairie ID non défini"}</td>
                                        <td className="py-3 px-4 text-center">{user._id || "ID non défini"}</td>
                                        <td className="py-3 px-4 flex justify-center space-x-4">
                                        <button 
                                            onClick={() => handleAccess(user._id)} 
                                            className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-4 rounded"
                                        >
                                            Accéder
                                        </button>
                                            <button onClick={() => handleEditUser(user)} className="bg-yellow-500 hover:bg-yellow-600 text-white py-1 px-4 rounded">Modifier</button>
                                            <button onClick={() => handleDeleteConfirmation(user._id)} className="bg-red-600 hover:bg-red-700 text-white py-1 px-4 rounded">Supprimer</button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="py-4 text-center text-gray-500">Aucun utilisateur trouvé.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

{/* Table des SuperAdmins */}
<div className="overflow-x-auto bg-white shadow-md rounded-lg mt-10">
    <h2 className="text-2xl font-bold text-blue-500 text-center mb-4">Liste des SuperAdmins</h2>

    <table className="min-w-full table-auto">
        <thead className="bg-gray-100 text-gray-700">
            <tr>
                <th className="py-3 px-4 text-center">Nom</th>
                <th className="py-3 px-4 text-center">Email</th>
                <th className="py-3 px-4 text-center">UserID</th>
                <th className="py-3 px-4 text-center">Actions</th>
            </tr>
        </thead>
        <tbody className="text-gray-700">
            {superadmins && superadmins.length > 0 ? (
                superadmins.map((user) => (
                    <tr key={user._id} className="border-b hover:bg-gray-50">
                        <td className="py-3 px-4">{user.name || "Nom non défini"}</td>
                        <td className="py-3 px-4">{user.email || "Email non défini"}</td>
                        <td className="py-3 px-4 text-center">{user._id || "ID non défini"}</td>
                        <td className="py-3 px-4 flex justify-center space-x-4">
                            <button 
                                onClick={() => handleEditUser(user)} 
                                className="bg-yellow-500 hover:bg-yellow-600 text-white py-1 px-4 rounded"
                            >
                                Modifier
                            </button>
                            <button 
                                onClick={() => handleDeleteConfirmation(user._id)} 
                                className="bg-red-600 hover:bg-red-700 text-white py-1 px-4 rounded"
                            >
                                Supprimer
                            </button>
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td colSpan="4" className="py-4 text-center text-gray-500">Aucun SuperAdmin trouvé.</td>
                </tr>
            )}
        </tbody>
    </table>
</div>

            </div>
            <Footer />
        </>
    );
}

export default SuperAdminValneo;