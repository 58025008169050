import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const PdfContext = createContext();

export function usePdfContext() {
  return useContext(PdfContext);
}

export function PdfContextProvider({ children }) {
  const [allPdfs, setAllPdfs] = useState([]);
  const token = localStorage.getItem('token');
  const { userId } = useParams();

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL+'/api',
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
    },
  });

  // Fonction pour récupérer les PDFs
  const fetchPdfs = async () => {
    try {
        console.log("Fetching PDFs...");

        let result;
        if (userId) {
            console.log(`🔍 Superadmin: récupération des PDFs de l'utilisateur ${userId}...`);
            result = await authAxios.get(`/superadmin/get-files/${userId}`);
        } else {
            result = await authAxios.get('/get-files', { params: { all: true } });
        }

        console.log("📝 Résultat brut de l'API :", result.data); 

        if (result.data && Array.isArray(result.data.data)) {
            console.log('📂 PDFs récupérés avec succès :', result.data.data);
            setAllPdfs(result.data.data);
        } else {
            console.error('🚨 Structure inattendue des données !', result.data);
            setAllPdfs([]);
        }
    } catch (error) {
        console.error('❌ Erreur lors de la récupération des PDFs', error);
        setAllPdfs([]);
    }
};


  // Récupérer les données lors du montage du contexte
  useEffect(() => {
    fetchPdfs();
  }, []);

  return (
    <PdfContext.Provider value={{ allPdfs, setAllPdfs, fetchPdfs }}>
      {children}
    </PdfContext.Provider>
  );
}
