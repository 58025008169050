import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

function Navbar() {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  // Récupérer le token et décoder l'utilisateur
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserRole(decoded.role);
      } catch (error) {
        console.error("Erreur lors du décodage du token :", error);
      }
    }

    // Vérifier s'il y a un userId sélectionné stocké (par SuperAdmin)
    const storedUserId = sessionStorage.getItem('selectedUserId');
    if (storedUserId) {
      setSelectedUserId(storedUserId);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token'); // Suppression du token
    sessionStorage.removeItem('selectedUserId'); // Supprime l'utilisateur sélectionné (superadmin)
    navigate('/'); // Redirection vers la page de connexion
    window.location.reload(); // Recharge la page pour appliquer les restrictions des routes protégées
  };

  return (
    <nav className="bg-blue-500 p-4">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center sm:space-x-4 space-y-2 sm:space-y-0">
        <Link 
          to={userRole === 'superadmin' && selectedUserId ? `/accueil/${selectedUserId}` : "/accueil"} 
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Accueil
        </Link>
        <Link 
          to={userRole === 'superadmin' && selectedUserId ? `/documents/${selectedUserId}` : "/documents"} 
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Voir ou modifier mes PDF
        </Link>
        {userRole === "superadmin" && selectedUserId ? (
          <Link 
            to="/superadminvalneo"
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Admin
          </Link>
        ) : (
          <button 
            onClick={handleLogout} 
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Se déconnecter
          </button>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
