import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Signup from './components/authentication/Signup';
import Login from './components/authentication/Login';
import UploadedFiles from './components/UploadedFiles';
import { PdfContextProvider } from './context/PdfContext';
import './App.css';
import FrontPdf from './components/FrontPdf';
import SuperAdminValneo from './components/SuperAdminValneo';
import jwtDecode from 'jwt-decode';
import { useEffect } from "react";

// Fonction pour récupérer le rôle depuis le token
const getUserRole = () => {
  const token = localStorage.getItem('token');
  if (!token) return null; // Aucun token trouvé, donc pas authentifié
  try {
    const decoded = jwtDecode(token);
    return decoded.role; // Retourne le rôle de l'utilisateur
  } catch (error) {
    console.error("Erreur lors du décodage du token :", error);
    return null;
  }
};

// Composant pour protéger les routes accessibles uniquement aux utilisateurs connectés
const ProtectedRoute = ({ element }) => {
  const token = localStorage.getItem('token');
  return token ? element : <Navigate to="/" />;
};

// Composant pour protéger les routes accessibles uniquement aux superadmins
const SuperAdminRoute = ({ element }) => {
  const role = getUserRole();
  return role === 'superadmin' ? element : <Navigate to="/accueil" />;
};

// Composant pour gérer la redirection si la page n'existe pas
const NotFoundRedirect = () => {
  const token = localStorage.getItem('token');

  if (token) {
    try {
      const decoded = jwtDecode(token);
      return decoded.role === "superadmin" ? <Navigate to="/superadminvalneo" replace /> : <Navigate to="/accueil" replace />;
    } catch (error) {
      console.error("Erreur lors du décodage du token :", error);
    }
  }

  return <Navigate to="/" replace />;
};

function App() {

    // **useEffect pour détecter une suppression d'utilisateur et le déconnecter**
    useEffect(() => {
      const checkForceLogout = () => {
        const forceLogout = localStorage.getItem('forceLogout');
        if (forceLogout === "true") {
          console.log("Utilisateur supprimé, déconnexion en cours...");
          localStorage.removeItem('token'); // Supprime le token de connexion
          localStorage.removeItem('forceLogout'); // Réinitialise l'état de déconnexion forcée
          window.location.href = "/"; // Redirige vers la page de connexion
        }
      };
  
      checkForceLogout();
      const interval = setInterval(checkForceLogout, 2000); // Vérifie toutes les 2 secondes
  
      return () => clearInterval(interval); // Nettoie l'intervalle quand le composant est démonté
    }, []);

  return (
      <Router>
        <PdfContextProvider>
        <div className="App">
        <Routes>
          {/* Routes publiques */}
          <Route path="/" element={<Login />} />
          <Route path="/inscription" element={<Signup />} />

          {/* Routes publique dynamiques */}
          <Route path="/mairie/:mairieId" element={<FrontPdf />} /> {/* Route dynamique pour mairieId */}
          <Route path="/id/:userId" element={<FrontPdf />} /> {/* Route dynamique pour userId */}

          {/* Routes protégées pour les utilisateurs connectés */}
          <Route path="/accueil" element={<ProtectedRoute element={<Home />} />} />
          <Route path="/documents" element={<ProtectedRoute element={<UploadedFiles />} />} />

          {/* Route pour accéder à la page de l'utilisateur via son ID */}
          <Route path="/accueil/:userId" element={<SuperAdminRoute element={<Home />} />} />
          <Route path="/documents/:userId" element={<SuperAdminRoute element={<UploadedFiles />} />} />

          {/* Route protégée pour le SuperAdmin */}
          <Route path="/superadminvalneo" element={<SuperAdminRoute element={<SuperAdminValneo />} />} />
          {/* Route "404" - Redirection vers la page de connexion si la page n'existe pas */}
          <Route path="*" element={<NotFoundRedirect />} />
        </Routes>
        </div>
        </PdfContextProvider>
      </Router>
  );
}

export default App;