import { useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); //  État pour afficher les erreurs spécifiques

  // Vérifier si l'utilisateur est déjà connecté
  const token = localStorage.getItem("token");
  if (token) {
    return <Navigate to="/accueil" replace />;
  }

  // Fonction pour gérer la connexion
  async function loginUser(event) {
    event.preventDefault();
    setError(""); //  Réinitialiser les erreurs avant chaque tentative

    // **Validation des champs côté client**
    if (!email.trim() || !email.includes("@")) {
      setError("Veuillez renseigner une adresse email valide.");
      return;
    }
    if (!password.trim()) {
      setError("Veuillez renseigner votre mot de passe.");
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/api/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok && data.user) {
        // Stocker le token
        localStorage.setItem("token", data.user);

        try {
          const decoded = jwtDecode(data.user); // Décoder le token pour récupérer le rôle
          navigate(decoded.role === "superadmin" ? "/superadminvalneo" : "/accueil");
        } catch (error) {
          console.error("Erreur lors du décodage du token :", error);
          navigate("/accueil"); // Sécurité : rediriger par défaut vers /accueil
        }
      } else {
        //  Message d'erreur personnalisé pour un login/mot de passe incorrect
        setError("Email ou mot de passe incorrect.");
      }
    } catch (error) {
      console.error("Erreur de connexion :", error);
      setError("Une erreur s'est produite. Veuillez réessayer.");
    }
  }

  return (
    <div className="login min-h-screen flex items-center justify-center bg-blue-400">
      <div className="max-w-md w-full p-8 bg-white shadow-lg rounded-md">
        <h1 className="text-3xl text-center font-semibold mb-4">Se connecter</h1>

        {/*  Affichage de l'erreur en rouge si elle existe */}
        {error && (
          <p className="text-red-600 bg-red-100 p-3 rounded-md text-center mb-4">
            {error}
          </p>
        )}

        <form onSubmit={loginUser}>
          <input
            value={email}
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full p-2 mb-2 border rounded-md"
          />
          
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mot de passe"
            className="w-full p-2 mb-2 border rounded-md"
          />

          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
          >
            Me connecter
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
