import React from 'react';
import { Link } from 'react-router-dom';
// import { usePdfContext } from '../context/PdfContext';

function HeaderPublic({ userInfo }) {
  // const {setAllPdfs} = usePdfContext()
  
  return (
    <header className="pt-12 text-white py-4 min-h-[150px] flex flex-col sm:flex-row items-center justify-between px-10">
      {/* Logo */}

      <div className="user-info mb-4">
        <h2 className="font-bold text-xl">{userInfo.name}</h2>
        {userInfo.profileImage ? (
        <img 
         src={`https://affichage-legal-api.mymairie.fr${userInfo.profileImage}`}
         alt="Logo" 
         className="w-[100px] h-auto" 
        />
        ) : (
        <span>Aucun logo</span> 
      )}
      </div>
      
    </header>
  );
}

export default HeaderPublic;