import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HeaderPublic from "./HeaderPublic";
import Pagination from "./Pagination";
import axios from "axios";

function FrontPdf() {
  const [userInfo, setUserInfo] = useState({});
  const [pdfs, setPdfs] = useState([]);
  const params = useParams(); // Récupérer tous les paramètres
  const userId = params.userId;
  const mairieId = params.mairieId; 
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedSort, setSelectedSort] = useState("newest");
  const [filteredPdfs, setFilteredPdfs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [categories, setCategories] = useState([]); // State pour les catégories

  // PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);

  const indexOfLastPdf = currentPage * itemsPerPage;
  const indexOfFirstPdf = indexOfLastPdf - itemsPerPage;
  const currentPdfs = filteredPdfs.slice(indexOfFirstPdf, indexOfLastPdf);

  const totalPages = Math.ceil(filteredPdfs.length / itemsPerPage);

    const authAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL+"/api", // URL du backend
    });
    
    const authAxiosFront = axios.create({
      baseURL: "https://affichage-legal-api.mymairie.fr", // URL du backend
    });
    useEffect(() => {
      if (userId || mairieId) {
        const getUserData = async () => {
          try {
            setLoading(true);
    
            // Déterminer l'ID à utiliser
            const url = mairieId ? `/mairie/${mairieId}` : `/${userId}`;
    
            const result = await authAxios.get(url);
            setUserInfo(result.data);
            console.log("PDF files récupérés :", result.data.pdfFiles);
            const pdfFiles = result.data.pdfFiles;
    
            // Assurer que chaque PDF a son nom de catégorie et non pas juste l'ID
            const updatedPdfs = pdfFiles.map(pdf => ({
              ...pdf,
              views: pdf.views,
              category: pdf.category || "Catégorie manquante",  // Ajouter le nom de la catégorie
            }));
    
            // Récupérer les catégories uniques des PDFs visibles
            const visiblePdfs = updatedPdfs.filter(pdf => isPdfVisible(pdf.startDate, pdf.endDate));
            const uniqueCategories = [
              ...new Set(visiblePdfs.map((pdf) => pdf.category).filter(Boolean)),
            ];
    
            // Trier les catégories par ordre alphabétique
            uniqueCategories.sort((a, b) => a.localeCompare(b));
            
            setCategories(uniqueCategories); // Mettre à jour l'état des catégories visibles
            setPdfs(updatedPdfs);
            setFilteredPdfs(updatedPdfs);
          } catch (error) {
            console.error("Erreur lors de la récupération des données utilisateur:", error);
          } finally {
            setLoading(false);
            setDataLoaded(true);
          }
        };
    
        getUserData();
      }
    }, [userId, mairieId]);  // Dépendances : récupérer les données au lancement    

  // incrementation des vues de chaque pdf
  const handleClickPdf = async (customId) => {
    try {
      // 1. Envoyer la requête PUT pour incrémenter les vues
      const response = await axios.put(`https://affichage-legal-api.mymairie.fr/api/pdfs/custom-id/${customId}/increment-views`);
      console.log('Nombre de vues incrémenté:', response.data);
  
      // 2. Mettre à jour filteredPdfs directement
      setFilteredPdfs(prevFilteredPdfs => prevFilteredPdfs.map(pdf =>
        pdf.customId === customId ? response.data : pdf
      ));
  
      // 3. Mettre à jour pdfs (state principal) pour la cohérence des données
      setPdfs(prevPdfs => prevPdfs.map(pdf =>
        pdf.customId === customId ? response.data : pdf
      ));
    } catch (error) {
      console.error('Erreur lors de l\'incrémentation des vues:', error);
    }
  };
  

  const isPdfVisible = (startDate, endDate) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Ignorer les heures, minutes, secondes et millisecondes
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);
    const end = new Date(endDate);
    end.setHours(0, 0, 0, 0);
    return currentDate >= start && currentDate <= end;
  };

  const isPdfVisibleByDate = (startDate, endDate, selectedDate) => {
    const selected = new Date(selectedDate);
    const start = new Date(startDate);
    const end = new Date(endDate);
    return selected >= start && selected <= end;
  };

  const filterPdfs = () => {
    let filtered = [...pdfs];
  
    if (searchQuery) {
      filtered = filtered.filter((pdf) => {
        const titleLower = pdf.title ? pdf.title.toLowerCase() : "";
        const categoryLower = typeof pdf.category === "string" ? pdf.category.toLowerCase() : "";
    
        return titleLower.includes(searchQuery.toLowerCase()) || categoryLower.includes(searchQuery.toLowerCase());
      });
    }    
  
    if (selectedCategory) {
      filtered = filtered.filter((pdf) => {
        const categoryName = (pdf.category?.name || pdf.category || "").trim().toLowerCase();
        const selectedCategoryName = selectedCategory.trim().toLowerCase();
        return categoryName === selectedCategoryName;
      });
    }
  
    if (selectedDate) {
      filtered = filtered.filter((pdf) =>
        isPdfVisibleByDate(pdf.startDate, pdf.endDate, selectedDate)
      );
    }
  
    filtered = filtered.filter((pdf) => isPdfVisible(pdf.startDate, pdf.endDate));
  
    // **Tri selon l'option sélectionnée**
    console.log("Tri sélectionné :", selectedSort); 
    if (selectedSort === "alphabetical") {
      filtered.sort((a, b) => a.title.localeCompare(b.title, "fr", { numeric: true }));
    } else if (selectedSort === "oldest") {
      filtered.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    } else if (selectedSort === "newest") {
      filtered.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    }
  
    // Met à jour l'état avec les PDFs filtrés
    setFilteredPdfs(filtered);
    setCurrentPage(1);
  };
  
  // ⚡ **Exécuter `filterPdfs` à chaque changement de filtre**
  useEffect(() => {
    console.log("Exécution du tri / filtre !");
    filterPdfs();
  }, [searchQuery, selectedCategory, selectedDate, selectedSort, pdfs]);

  // fonction pour truncate le texte si il est trop long
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  const displayedCategories = categories; // Utiliser directement les catégories visibles

  return (
    <div>
      <HeaderPublic userInfo={userInfo} />

      <div className="filters mb-8 w-full flex flex-wrap gap-2 justify-between px-10">
        <input
          type="text"
          placeholder="Rechercher un PDF..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="p-2 border border-gray-300 rounded-md flex-grow sm:w-1/4 lg:w-1/5"
        />

        <select
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
        className="p-2 border border-gray-300 rounded-md flex-grow sm:w-1/4 lg:w-1/5"
        >
        <option value="">Toutes les catégories</option>
        {displayedCategories.map((category, index) => (
          <option key={index} value={category}>
            {category}
          </option>
        ))}
        </select>

        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="p-2 border border-gray-300 rounded-md flex-grow sm:w-1/4 lg:w-1/5"
        />

<select
  value={selectedSort}
  onChange={(e) => {
    setSelectedSort(e.target.value);
    filterPdfs(); // Exécute immédiatement après mise à jour
  }}
  className="p-2 border border-gray-300 rounded-md flex-grow sm:w-1/4 lg:w-1/5"
>
  <option value="newest">Les plus récents</option>
  <option value="oldest">Les plus anciens</option>
  <option value="alphabetical">Ordre alphabétique</option>
</select>

      </div>

      <div className="px-[30px] lg:px-[4rem] container-pdf-bo">
        {loading ? (
          <p>Chargement des fichiers PDF...</p>
        ) : filteredPdfs.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {console.log("current pdf object:", currentPdfs)} {/* <-- Afficher currentPdfs ici */}
            {currentPdfs.map((data, index) => (
              <a
              href={`https://affichage-legal-api.mymairie.fr/assets/pdf/${data.pdf}` || "#"}
                target="_blank"
                rel="noopener noreferrer"
                className="border border-gray-300 shadow-md hover:shadow-xl rounded-lg transition-transform transform hover:-translate-y-2 bg-white flex flex-col justify-between overflow-hidden group"
                key={index}
                style={{ height: "380px" }}
                onClick={() => {
                  console.log("ID du PDF:", data.customId); // <-- Afficher l'ID dans la console
                  handleClickPdf(data.customId);
                }}
                
              >
                <div className="p-4">
                  <h3 className="font-semibold text-2xl text-gray-800 line-clamp-4 mb-2">
                    {data.title || "Titre manquant"}
                  </h3>
                  <p className="text-lg text-teal-400 mb-2">
                    {/* Afficher la catégorie si elle est disponible */}
                    {truncateText(data.category?.name || data.category || "Catégorie manquante", 50)} {/* Remplacez 50 par le nombre de caractères maximum souhaité */}
                  </p>
                  {data.startDate && data.endDate ? (
                    <p className="text-sm text-gray-500">
                      Publié le {new Date(data.startDate).toLocaleDateString()}
                    </p>
                  ) : (
                    <p className="text-sm text-gray-500">
                      Dates de période manquantes
                    </p>
                  )}
                  {/* Ajouter une ligne pour afficher le nombre de vues */}
                   <p className="text-sm text-gray-500 absolute bottom-[60px] left-1/2 -translate-x-1/2">
                   {data.views === 0 || data.views === 1 ? `${data.views} vue` : `${data.views} vues`}                 </p>
                </div>

                <div className="bg-gray-50 p-3 border-t border-gray-200 flex justify-center group-hover:bg-teal-400 transition-colors">
                  <button className="text-teal-400 font-semibold text-sm group-hover:text-white transition-colors uppercase">
                    Consulter
                  </button>
                </div>
              </a>
            ))}
          </div>
        ) : dataLoaded ? (
          <p>Aucun fichier PDF trouvé.</p>
        ) : null}
      </div>
     {/* Pagination */}
       <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredPdfs.length}
            paginate={(pageNumber) => setCurrentPage(pageNumber)}
            setItemsPerPage={setItemsPerPage}
       />
    </div>
  );
}

export default FrontPdf;